const navbarAll = "#ffffff"
const darkAll = "#1c2c65"
const secondColorAll = "#d81f27"


export const header = "#012a45";
// export const navbar = "#000000";
// export const lightText = "#4f4c4c";
export const darkText = "#000000";
export const lightButton = "#eeecff";
export const darkButton = "#ffcd24";
export const dimBackground = "#e6e3e3";
export const lightBackground = darkAll;
export const darkBackground = "#303030";
export const white = "#ffffff";

//theme color for Brand Invoice

// export const brandName = "theassignmenthelpers";
export const brandName = "Academics Pro";
export const brandUrl = "Academicspro.co.uk";

export const navbar = navbarAll;
export const navbarText = darkAll;
export const lightText = navbarAll;
export const mainBackgound = darkAll;
export const invoiceCard = "#e1e2e6";
export const button = "#ea0000";
export const cardBackground = "#e1e2e6";

